/* src/components/Navbar.css */
@font-face {
    font-family: 'MainFont';
    src: url('Fonts/Raleway.ttf') format('truetype');
}

.navbar {
    padding: 1rem;
    text-align: center;
    font-size: 3rem;
    font-family: 'MainFont';
}

.navbar a {
    position: relative;
    margin: 1rem; /* Daha dar ekranlar için margin azaltıldı */
    color: white;
    text-decoration: none;
    display: inline-block; /* Box modelini korur */
    transition: color 0.3s, text-shadow 0.3s, transform 0.3s; /* Geçiş efektleri */
    font-weight: bold; /* Yazı kalınlığı */
    text-shadow: 
        1px 1px 3px rgba(255, 255, 255, 0.6), 
        2px 2px 4px rgba(255, 254, 254, 0.5), 
        3px 3px 6px rgba(255, 255, 255, 0.6); /* Gölgeler */
}

/* 3D Efekt */
.navbar a:hover {
    color: white; /* Yazı rengi */
    text-shadow: 
        1px 1px 0 #ddd, 
        2px 2px 0 #ccc, 
        3px 3px 0 #bbb, 
        4px 4px 0 #aaa, 
        5px 5px 0 #999, 
        6px 6px 0 #888, 
        7px 7px 0 #777, 
        8px 8px 0 #666; /* Derinlik için gölgeler */
    transform: perspective(500px) rotateX(10deg) rotateY(10deg); /* 3D dönüşüm */
}

/* Responsive ayarlar */
@media (max-width: 768px) {
    .navbar {
        font-size: 2rem !important; /* Küçük ekranlar için font boyutu */
    }
    .navbar a {
        margin: 1rem !important; /* Margin azaltıldı */
        font-size: 3rem !important; /* Daha küçük ekranlarda font boyutu */
    }
}

@media (max-width: 480px) {
    .navbar {
        font-size: 1.5rem !important; /* Küçük ekranlar için daha küçük font boyutu */
    }
    .navbar a {
        margin: 0.5rem !important; /* Margin daha da azaltıldı */
        font-size: 1.5rem !important;
        text-shadow: 
        1px 1px 1px rgba(255, 255, 255, 0.6), 
        1px 1px 1px rgba(255, 254, 254, 0.5), 
        1px 1px 1px rgba(255, 255, 255, 0.6); /* Gölgeler */
    }
}
