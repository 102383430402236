@font-face {
    font-family: 'MainFont';
    src: url('Fonts/Raleway.ttf');
}

.home2-background {
    background-color: white;
    height: 50rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    position: relative;
    z-index: 2;
}

.About-Me {
    font-size: 3rem;
    text-align: center;
    font-family: 'MainFont';
}

.content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    max-width: 80%;
    width: 100%;
}

.homepage-image {
    width: 40%;
    margin-right: 2rem;
}

.About-Me-Text {
    font-size: 1.5rem;
    font-family: 'MainFont';
    width: 60%;
    line-height: 1.6;
    text-align: justify; /* Metni iki yana hizalar */
    margin: 0; /* Varsayılan margin değerlerini sıfırlıyoruz */
}
@media (max-width: 768px) {
    .content-container {
        flex-direction: column !important; /* Mobil cihazlarda dikey düzenleme */
        align-items: flex-start;
    }

    .homepage-image {
        width: 60% !important;
        margin-right: 0;
        margin-bottom: 1rem; /* Resim ile metin arasında boşluk */
    }

    .About-Me-Text {
        width: 100%;
        font-size: 10px;
        position: block !important;
    }
}