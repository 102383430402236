@font-face {
    font-family: 'MainFont';
    src: url('Fonts/Raleway.ttf');
}
@font-face {
    font-family: 'IconFont';
    src: url('Fonts/Social_Shapes.ttf');
}

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  
  .text-image-container {
    flex: 1; /* İçerik alanının footer'a kadar uzanmasını sağlar */
  }

  footer {
    background-color: none;
    color: white;
    padding: 20px;
    text-align: center;
    position: relative;
    margin-top: auto;
    display: flex;
    justify-content: space-between; /* Sol ve sağ öğeleri birbirine uzaklaştırır */
    align-items: center; /* Yükseklikleri ortalar */
  }
  
  .footer-left, .footer-right {
    display: flex;
    align-items: center; /* İçerikleri ortalar */
  }
  
  .footer-left {
    flex: 1; /* Sol tarafı genişletir */
  }
  
  .footer-right {
    flex: 1; /* Sağ tarafı genişletir */
    justify-content: flex-end; /* Sağ tarafa hizalar */
  }
  
  .footer-right a {
    font-family: 'IconFont';
    text-decoration: none;
    color: white;
    display: block;
    margin-left: 20px; /* Linkler arasında boşluk bırakır */
    font-size: 4vh;
  }
  @media (max-width: 768px) {
    body {
        font-size: 0.8rem;
    }
    .footer-right a{
      font-size: 1.5rem !important;
    }

}