/* src/components/AnimatedBackground.css */

body {
    margin: 0;
    overflow:auto;
    height: 100vh;
    background: #000; /* Arka plan rengi */
}

/* Arka plan kapsayıcı */
.background {
    position: fixed; /* Sabit konumda kalmasını sağlar */
    top: 0;
    left: 0;
    width: 100%; /* Genişliği tam olarak kapsar */
    height: 100%; /* Yüksekliği tam olarak kapsar */
    background-size: cover; /* Arka planın tam ekranı kaplamasını sağlar */
    background-attachment: fixed; /* Arka planın sabit kalmasını sağlar */
    pointer-events: none; /* Arka planın tıklanabilir olmasını engeller */
}


/* Noktalar */
.dot {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    pointer-events: none; /* Noktaların tıklanabilir olmasını engeller */
    color: white; /* Simgenin rengi */
}

/* Font ayarları */
@font-face {
    font-family: 'Planets';
    src: url('Planets.otf') format('truetype');
}

/* Dot sınıfı için font ayarları */
.dot {
    font-family: 'Planets';
    font-size: 6px; /* Noktaların boyutunu ayarlayın */
}

/* Responsive ayarlar */
@media (max-width: 1024px) {
    .dot {
        width: 4px; /* Daha küçük ekranlarda nokta boyutunu azaltın */
        height: 4px;
        font-size: 5px; /* Font boyutunu da küçültün */
    }
}

@media (max-width: 768px) {
    .dot {
        width: 3px; /* Mobil ekranlarda daha küçük nokta boyutu */
        height: 3px;
        font-size: 4px; /* Font boyutunu da küçültün */
    }
}
.animatedbackground {
    background-size: cover; /* Arka planı tam olarak kapsar */
    width: 100vw; /* Görüntüleme alanının genişliği kadar */
    height: 100vh; /* Görüntüleme alanının yüksekliği kadar */
    position: fixed; /* Sayfa kaydırıldığında bile sabit kalır */
    top: 0;
    left: 0;
}
