@font-face {
    font-family: 'MainFont';
    src: url(Fonts/Raleway.ttf);
}
.text1 {
    font-family: 'MainFont';
    display: flex;
    flex-direction: row; /* Yatay hizalamayı dikey hizalamaya çevirdik */
    align-items: center; /* Merkezi hizalama */
    justify-content: center; /* Merkezi hizalama */
    color: white;
    padding: 0;
    text-align: left;
    line-height: clamp(2rem, 5vw, 5rem);
    min-height: 85vh;
}

.text-container {
    display: flex;
    flex-direction: column; /* Yazıların alt alta gelmesini sağlar */
    margin: 0;
    text-align: left;
}

.text1 .text-container .MyFuture {
    font-size: clamp(2rem, 3vw, 3.5rem);
    color: rgb(0, 0, 0);
    background: url(https://s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=00FDCF);
    margin: -2px -6px;
    padding: 1px 20px;
    -webkit-animation: none !important;
    animation: none !important;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 1),
                 0 1px 5px rgba(0, 0, 0, 1);
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.welcome-text {
    font-size: clamp(1.5rem, 3vw, 3rem);
    -webkit-animation: text-shadow-pop-right 0.6s both;
    animation: text-shadow-pop-right 0.6s both;
    margin: 0;
}

.homepage-image {
    width: clamp(40%, 50vw, 60%);
    max-width: 400px;
    height: auto;
    margin: 20px 0; /* Üst ve alt boşluk ekledik */
}

.My-Name {
    position: relative;
    white-space: nowrap;
}

.My-Name:after {
    --deco-height: 0.3125em;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(var(--deco-height) * -0.625);
    height: var(--deco-height);
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M-17 30.5C-1 22 72-4 54 13 37.9 28.2-2.5 57.5 16 55.5s72-29 104-40' stroke='%2300FDCF' stroke-width='10'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h100v64H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: auto 100%;
    background-repeat: round;
    background-position: 0em;
}

.waving-hand {
    display: inline-block;
    animation: wave 1s infinite;
}

.typewrite {
    pointer-events: none;
    text-decoration: none;
    color: #00FDCF;
    font-size: clamp(2rem, 3vw, 3rem);
    z-index: 2;
}

@keyframes wave {
    0%, 100% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(20deg);
    }
}

@keyframes text-shadow-pop-right {
    0% {
      text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      text-shadow: 1px 0 #555555, 2px 0 #555555, 3px 0 #555555, 4px 0 #555555, 5px 0 #555555, 6px 0 #555555, 7px 0 #555555, 8px 0 #555555;
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
}

/* Tablet Screens */
@media only screen and (max-width: 768px) {
    .text1 {
        flex-direction: column !important; /* Yazıları ve resmi üst üste yerleştir */
        align-items: center !important; /* Merkezi hizalama */
        padding: 20px !important;
        text-align: center !important; /* Mobilde merkezi hizalama */
        font-size: 0.4rem !important;
        z-index: 1000 !important;
    }

    .text-container {
        margin-bottom: 20px !important; /* Metin ve resim arasında boşluk */
        font-size: 1rem !important;
    }

    .homepage-image {
        width: 70% !important;
        margin: 10px auto !important; /* Merkezi hizalama */
    }

    .text1 .text-container .MyFuture {
        font-size: 1.5rem !important; /* Mobilde daha küçük font boyutu */
        text-shadow: 0 1px 1px rgba(0, 0, 0, 1),
        0 1px 1px rgba(0, 0, 0, 1) !important;
    }
}